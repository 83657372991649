body {
	background: #111;
	margin: 0;
	padding: 0;
	font-family: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
	"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
	sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	overflow: hidden;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
	monospace;
}

.canvas {
	width: 100vw;
	height: 100vh;
	cursor: -webkit-grab;
	cursor: grab;
}

.canvas.is-mouse-down {
	cursor: -webkit-grabbing;
	cursor: grabbing;
}

.button-group-vertical{
	position: fixed;
	top: 5px;
	right: 5px;
	display: flex;
	flex-direction: column;
}

.trans-button{
	padding: 10px;
	background-color: transparent;
	border: none;
	color: #fff;
	line-height: 0;
	cursor: pointer;
	margin: 5px;
}

.trans-button:hover{
	background-color: rgba(255,255,255,.1);
}


.trans-button img{
	width: 24px;
	height: 24px;
}


.button-group {
	position: absolute;
	z-index: 150;
	bottom: 5%;
	right: 5%;
	display: grid;
	grid-template-columns: auto auto auto;
	grid-column-gap: 16px;
}

.button {
	position: relative;
	width: 150px;
	height: 20px;
	text-align: center;
	border-radius: 10px;
	overflow: hidden;
	cursor: pointer;
	-webkit-user-select: none;
	   -moz-user-select: none;
	    -ms-user-select: none;
	        user-select: none;
}

.button::before {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	z-index: -10;
	width: 100%;
	height: 100%;
	background: #fff;
	opacity: 0.8;
}

.button:hover::before {
	opacity: 1;
}

.is-hidden {
	opacity: 0.2;
	/*display: none;*/
}

.instructions{
	position: absolute;
	left: 50%;
	-webkit-transform: translateX(-50%);
	        transform: translateX(-50%);
	bottom: 5%;
	color: rgba(255,255,255,0.5);
}

.instructionGroup{
	display: flex;
	align-items: center;
}

.instruction-feedback{
	background: rgba(255,255,255, 0);
	padding: 10px;
	margin: 30px;
	display: inline-block;
	vertical-align: middle;
	-webkit-transition: 200ms all ease-out;
	transition: 200ms all ease-out;
	border-radius: 4px;
	border: 1px solid rgba(255,255,255,0.2);
}

.instruction-feedback.is-active{
	background: rgba(255,255,255, 0.1);
}

.instruction-feedback img{
	width: 36px;
	height: 36px;
}
